import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Dropdown} from "react-bootstrap";
import {useMatchMedia} from "../hooks/useMatchMedia";


const LanguageSelector = () => {
    const isMobile = useMatchMedia(`(max-width: 768px)`)

    const {i18n} = useTranslation();
    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    }
    const options = ['DE', 'EN']
    const [selectedOption, setSelectedOption] = useState(i18n.language.toUpperCase())

    if (isMobile) {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="secondary">{selectedOption}</Dropdown.Toggle>

                <Dropdown.Menu>
                    {options.map((value, index) =>
                        <Dropdown.Item key={index}
                                       onClick={() => {
                                           setSelectedOption(value)
                                           i18n.changeLanguage(value.toLowerCase())
                                       }}>{value}</Dropdown.Item>)
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    } else {
        return (
            <div className="change-language">
                {i18n.language === "de" ?
                    <div className="change-language-row" onClick={changeLanguage}>
                        <button type="radio" className="button language checked" value="de" name="language">DE</button>
                        <label>| </label>
                        <button type="radio" className="button language" value="en" name="language">EN</button>
                    </div> :
                    <div className="change-language-row" onClick={changeLanguage}>
                        <button type="radio" className="button language" value="de" name="language">DE</button>
                        <label>|</label>
                        <button type="radio" className="button language checked" value="en" name="language">EN</button>
                    </div>}
            </div>
        );
    }
};


export default LanguageSelector;