import React from "react";

/**
 * Sidebar for Login & Registration pages
 * @returns Sidebar component with logo & text (will not be displayed on smaller screen sizes)
 */

function SidebarLogReg() {

    // const { t, i18n } = useTranslation();

    return (
        <div>
            <aside className="sidebar-login-register">
                <div className="testphase"><p>** TESTPHASE **</p></div>
                <div className="box-logo up-logo">
                    <img src="./images/upLogo-noBG.png" className="img up-logo" alt="up-logo-img"></img>
                </div>
                <div className="box-welcome">
                    <h2 className="text-welcome">Willkommen bei
                        <br></br>
                        UNIted Parents!</h2>
                    {/*<h2 className="text-welcome">{t("welcome")}*/}
                    {/*    <br></br>*/}
                    {/*    {t("appname")}!</h2>*/}
                </div>

            </aside>
        </div>
    );
}

export default SidebarLogReg;