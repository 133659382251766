import React, {useState} from "react";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {Link} from 'react-router-dom'
import {Button, Card, Form} from 'react-bootstrap'

function Password() {
    const [email, setEmail] = useState("");
    const auth = getAuth();

    function ChangePassword() {

        sendPasswordResetEmail(auth, email).then(() => {
            alert("Eine E-Mail zum Zurücksetzen deines Passworts wurde versendet!")
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorCode + " " + errorMessage)
        });
    }

    return (
        <div className="container-login-register">
            <div className="box-content">
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4">Login</h1>
                        <div className="box-logo up-logo">
                            <img src="./images/upLogo-noBG.png" className="up-logo-img" alt="up-logo-img"></img>
                        </div>

                        <Form onSubmit={ChangePassword}>


                            <Form.Group id="Email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="Email"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}/>
                            </Form.Group>
                            <Button className="w-100" type="register">Zurücksetzen</Button>
                        </Form>
                    </Card.Body>
                    <div className="w-100 text-center mt-2">
                        Du hast noch keinen Account?<br></br>
                        <Link to="/registration">Registriere dich hier</Link>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Password;