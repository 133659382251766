import Motivation from "../components/Motivation";
import React, {useState} from "react";
import {useUserAuth} from "../context/UserAuthContext"
import {HomeFirstHelp} from "./HomeFirst";

/**
 * Home content
 * Function to create HomeInfoBox Components filled with info provided from homeData.js
 * @returns Home Page
 */

function GuHome() {
    const {user} = useUserAuth();
    const [popUp, setPopUp] = useState(false)

    const togglePopUp = async () => {
        setPopUp(!popUp)
    }

    return (
        <div>

            {popUp ?
                <HomeFirstHelp/> : null}

            <div className="container-contents">

                <Motivation togglePopUp={togglePopUp}/>

            </div>
        </div>

    );
}

export default GuHome;