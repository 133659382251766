import React, {useEffect, useState} from "react";
import {Alert, Button, Card, Form, OverlayTrigger} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {useUserAuth} from "../context/UserAuthContext"
import {tooltips} from "../utils/tooltips";
import {postData} from "../helpers/HttpService";

const Registration2 = () => {
    const {user} = useUserAuth();
    const [uid, setUid] = useState('');
    const [email, setEmail] = useState('');
    const [userName, setUsername] = useState("")
    const [birthday, setBirthday] = useState("")
    const [plz, setPlz] = useState("")
    const [city, setCity] = useState("")
    const [course, setCourse] = useState("")
    const [error, setError] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        setEmail(user.email)
        setUid(user.uid);
        console.log(user.uid);
    }, []);

    const createUser = async () => {
        try {
            await postData('/createuser', {
                uid: uid,
                username: userName,
                age: birthday,
                plz: plz,
                email: email,
                city: city,
                course: course
            }, user.accessToken)
            return true
        } catch (exception) {
            console.error(`Error while creating a User: ${exception}`)
            setError(`Beim Erstellen ist ein Fehler aufgetreten: ${exception}`)
            return false
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        if (userName === "GELÖSCHT" || userName === "admin") {
            setError("Username kann nicht " + userName + " sein.")
            alert(error)
        }
        try {
            let success = await createUser();
            if (success) {
                navigate("/homefirst")
            }
        } catch (err) {
            setError("Falsche E-Mail oder Passwörter stimmen nicht überein!");
            alert(error)
        }
    }

    return (
        <div className="container-login-register">
            <div className="box-content">
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4">Registrieren</h1>
                        <div className="box-logo up-logo">
                            <img src="./images/upLogo-noBG.png" className="up-logo" alt="up-logo-img"></img>
                        </div>
                        {error && <Alert variant="danger">{error}</Alert>}

                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="userName">
                                <Form.Label>Username <OverlayTrigger trigger="click" placement="top"
                                                                     overlay={tooltips.tt_username}>
                                    <span className="material-icons material-icons-small">error_outline</span>
                                </OverlayTrigger>
                                </Form.Label>
                                <Form.Control
                                    type="userName"
                                    placeholder="*Username"
                                    onChange={(e) => setUsername(e.target.value)}
                                    required/>
                            </Form.Group>

                            <Form.Group id="birthday">
                                <Form.Label>Geburtsdatum</Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="TT.MM.JJJJ"
                                    onChange={(e) => setBirthday(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="course">
                                <Form.Label>Studiengang</Form.Label>
                                <Form.Control
                                    type="course"
                                    placeholder="Studiengang"
                                    onChange={(e) => setCourse(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="Standort">
                                <Form.Label>Standort</Form.Label>
                                <Form.Control
                                    type="Standort"
                                    placeholder="*Standort"
                                    onChange={(e) => setCity(e.target.value)}
                                    required/>
                            </Form.Group>

                            <Form.Group id="plz">
                                <Form.Label>Postleitzahl</Form.Label>
                                <Form.Control
                                    type="Postleitzahl"
                                    placeholder="*PLZ"
                                    onChange={(e) => setPlz(e.target.value)} required/>
                            </Form.Group>
                            <p>* Pflichtfelder</p>
                            <div className="d-grid gap-2">
                                <Button className="w-100" varient="primary" type="register">Registrieren</Button>
                            </div>
                        </Form>
                    </Card.Body>
                    <div className="w-100 text-center mt-2">
                        Du hast bereits einen Account?<br></br>
                        <Link to="/">Log dich hier ein</Link>
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Registration2;
