import React, {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import {auth} from "../Firebase"
import {useUserAuth} from "../context/UserAuthContext"
import {useTranslation} from "react-i18next"
import {socket} from "../helpers/HttpService";
import {S_SCHNIER_EMAIL} from "../config/Constants";


/**
 * This is the Sidebar which is fixed vertically on the left underneath the header
 * @returns Sidebar component with icons for navigation routes
 */

function Sidebar({logoutOnly = false}) {
    const {user} = useUserAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        socket.on("UPDATE", (msg) => {
            console.log("UPDATE in SIDEBAR: ", msg)
            if (user && (window.location.pathname !== "/chats" && msg.receiver === user.uid)) {
                setNotification(true)
            }
        })
    }, [])

    const signOut = async () => {
        navigate("/login")
        await auth.signOut();
    };

    return (
        <aside className="sidebar">

            <div className="container-icons">
                {!logoutOnly ? <>
                    {user && (user.email?.includes("@stud.fra-uas.de") || user.email?.includes("@united-parents.de") || user.email?.includes("denis.neumann")) ?
                        <div className="box-icon">
                            <Link to="/home">
                                <span className="material-icons">home</span>
                                <p>{t("homepage")}</p>
                            </Link>
                        </div> :
                        user && (user.email?.includes("@gmx.net") || user.email?.includes("@uni-frankfurt.de") || user.email?.includes("@stud.uni-frankfurt.de") || user.email?.toLowerCase() === S_SCHNIER_EMAIL) ?
                            <div className="box-icon">
                                <Link to="/Ghome">
                                    <span className="material-icons">home</span>
                                    <p>{t("homepage")}</p>
                                </Link>
                            </div> :
                            <div></div>
                    }

                    {user && user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                        <Link to="/my-profile">
                            <span className="material-icons">account_circle</span>
                            <p>{t("myprofile")}</p>
                        </Link>
                    </div>}

                    {user && user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                        <Link to="/search-user">
                            <span className="material-icons">map</span>
                            <p>{t("searchuser")}</p>
                        </Link>
                    </div>}

                    {user && user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                        <Link className="chat" to="/chats" onClick={() => setNotification(false)}>
                            <span className="material-icons">forum</span>
                            <p>{t("chats")}</p>
                            {notification ? <div className="notification"></div> : null}
                        </Link>
                    </div>}

                    <div className="box-icon">
                        <Link
                            to={user && (user.email?.includes("@stud.fra-uas.de") || user.email?.includes("@united-parents.de") || user.email?.includes('denis.neumann')) ? "/help-finances" : "/Ghelp-finances"}>
                            <span className="material-icons">contact_support</span>
                            <p>{t("helpfinances")}</p>
                        </Link>
                    </div>

                    {user && user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                        <Link to="/settings">
                            <span className="material-icons">settings</span>
                            <p>{t("settings")}</p>
                        </Link>
                    </div>}
                </> : <></>}
                <div className="box-icon">
                    <div onClick={signOut}>
                        <span className="material-icons">logout</span>
                        <p>{t("logout")}</p>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
