import React, {useEffect, useState} from "react";
import {Card, Form} from 'react-bootstrap';
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {API_URL} from "../helpers/HttpService";

/**
 * Page where users can edit their profile
 * @returns edited user info (displayed at My Profile page)
 */

function EditHelpAndFinance() {

    const [contactContent, setContactContent] = useState('');
    const [contactTitle, setContactTitle] = useState('');
    const [contactUrl, setContactUrl] = useState('');

    const [careContent1, setCareContent1] = useState('');
    const [careTitle1, setCareTitle1] = useState('');
    const [careUrl1, setCareUrl1] = useState('');

    const [careContent2, setCareContent2] = useState('');
    const [careTitle2, setCareTitle2] = useState('');
    const [careUrl2, setCareUrl2] = useState('');

    const [careContent3, setCareContent3] = useState('');
    const [careTitle3, setCareTitle3] = useState('');
    const [careUrl3, setCareUrl3] = useState('');

    const [careContent4, setCareContent4] = useState('');
    const [careTitle4, setCareTitle4] = useState('');
    const [careUrl4, setCareUrl4] = useState('');

    const [financeContent1, setFinanceContent1] = useState('');
    const [financeTitle1, setFinanceTitle1] = useState('');
    const [financeUrl1, setFinanceUrl1] = useState('');

    const [financeContent2, setFinanceContent2] = useState('');
    const [financeTitle2, setFinanceTitle2] = useState('');
    const [financeUrl2, setFinanceUrl2] = useState('');

    const [pregnancyContent1, setPregnancyContent1] = useState('');
    const [pregnancyTitle1, setPregnancyTitle1] = useState('');
    const [pregnancyUrl1, setPregnancyUrl1] = useState('');

    const [pregnancyContent2, setPregnancyContent2] = useState('');
    const [pregnancyTitle2, setPregnancyTitle2] = useState('');
    const [pregnancyUrl2, setPregnancyUrl2] = useState('');

    const [parentsContent1, setParentsContent1] = useState('');
    const [parentsTitle1, setParentsTitle1] = useState('');
    const [parentsUrl1, setParentsUrl1] = useState('');

    const [parentsContent2, setParentsContent2] = useState('');
    const [parentsTitle2, setParentsTitle2] = useState('');
    const [parentsUrl2, setParentsUrl2] = useState('');

    const [studyOrgaContent1, setStudyOrgaContent1] = useState('');
    const [studyOrgaTitle1, setStudyOrgaTitle1] = useState('');
    const [studyOrgaUrl1, setStudyOrgaUrl1] = useState('');

    const [studyOrgaContent2, setStudyOrgaContent2] = useState('');
    const [studyOrgaTitle2, setStudyOrgaTitle2] = useState('');
    const [studyOrgaUrl2, setStudyOrgaUrl2] = useState('');

    const [supportContent1, setSupportContent1] = useState('');
    const [supportTitle1, setSupportTitle1] = useState('');
    const [supportUrl1, setSupportUrl1] = useState('');

    const [supportContent2, setSupportContent2] = useState('');
    const [supportTitle2, setSupportTitle2] = useState('');
    const [supportUrl2, setSupportUrl2] = useState('');

    const [connectionContent, setConnectionContent] = useState('');
    const [connectionTitle, setConnectionTitle] = useState('');
    const [connectionUrl, setConnectionUrl] = useState('');


    const [error,
        setError] = useState("");


    const {t, i18n} = useTranslation();
    const navigate = useNavigate()


    useEffect(() => {


        const getContent = async () => {
            try {
                let response = await axios.get(API_URL + `helpAndFinance/`
                )

                console.log(response)
                setContactContent(response.data
                    ?.[0].contactContent)
                setContactTitle(response.data
                    ?.[0].contactTitle)
                setContactUrl(response.data
                    ?.[0].contactUrl)
                setCareContent1(response.data
                    ?.[0].careContent1)
                setCareTitle1(response.data
                    ?.[0].careTitle1)
                setCareUrl1(response.data
                    ?.[0].careUrl1)
                setCareContent2(response.data
                    ?.[0].careContent2)
                setCareTitle2(response.data
                    ?.[0].careTitle2)
                setCareUrl2(response.data
                    ?.[0].careUrl2)
                setCareContent3(response.data
                    ?.[0].careContent3)
                setCareTitle3(response.data
                    ?.[0].careTitle3)
                setCareUrl3(response.data
                    ?.[0].careUrl3)

                setCareContent4(response.data
                    ?.[0].careContent4)
                setCareTitle4(response.data
                    ?.[0].careTitle4)
                setCareUrl4(response.data
                    ?.[0].careUrl4)

                setFinanceContent1(response.data
                    ?.[0].financeContent1)
                setFinanceTitle1(response.data
                    ?.[0].financeTitle1)
                setFinanceUrl1(response.data
                    ?.[0].financeUrl1)

                setFinanceContent2(response.data
                    ?.[0].financeContent2)
                setFinanceTitle2(response.data
                    ?.[0].financeTitle2)
                setFinanceUrl2(response.data
                    ?.[0].financeUrl2)
                setPregnancyContent1(response.data
                    ?.[0].pregnancyContent1)
                setPregnancyTitle1(response.data
                    ?.[0].pregnancyTitle1)
                setPregnancyUrl1(response.data
                    ?.[0].pregnancyUrl1)

                setPregnancyContent2(response.data
                    ?.[0].pregnancyContent2)
                setPregnancyTitle2(response.data
                    ?.[0].pregnancyTitle2)
                setPregnancyUrl2(response.data
                    ?.[0].pregnancyUrl2)
                setParentsContent1(response.data
                    ?.[0].parentsContent1)
                setParentsTitle1(response.data
                    ?.[0].parentsTitle1)
                setParentsUrl1(response.data
                    ?.[0].parentsUrl1)
                setParentsContent2(response.data
                    ?.[0].parentsContent2)
                setParentsTitle2(response.data
                    ?.[0].parentsTitle2)
                setParentsUrl2(response.data
                    ?.[0].parentsUrl2)
                setStudyOrgaContent1(response.data
                    ?.[0].studyOrgaContent1)
                setStudyOrgaTitle1(response.data
                    ?.[0].studyOrgaTitle1)
                setStudyOrgaUrl1(response.data
                    ?.[0].studyOrgaUrl1)
                setStudyOrgaContent2(response.data
                    ?.[0].studyOrgaContent2)
                setStudyOrgaTitle2(response.data
                    ?.[0].studyOrgaTitle2)
                setStudyOrgaUrl2(response.data
                    ?.[0].studyOrgaUrl2)
                setSupportContent1(response.data
                    ?.[0].supportContent1)
                setSupportTitle1(response.data
                    ?.[0].supportTitle1)
                setSupportUrl1(response.data
                    ?.[0].supportUrl1)
                setSupportContent2(response.data
                    ?.[0].supportContent2)
                setSupportTitle2(response.data
                    ?.[0].supportTitle2)
                setSupportUrl2(response.data
                    ?.[0].supportUrl2)

                setConnectionContent(response.data
                    ?.[0].connectionContent)
                setConnectionTitle(response.data
                    ?.[0].connectionTitle)
                setConnectionUrl(response.data
                    ?.[0].connectionUrl)


            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }

        getContent()

    }, []);

    const ContentData = {

        contactContent: contactContent,
        contactTitle: contactTitle,
        contactUrl: contactUrl,

        careContent1: careContent1,
        careTitle1: careTitle1,
        careUrl1: careUrl1,

        careContent2: careContent2,
        careTitle2: careTitle2,
        careUrl2: careUrl2,

        careContent3: careContent3,
        careTitle3: careTitle3,
        careUrl3: careUrl3,

        careContent4: careContent4,
        careTitle4: careTitle4,
        careUrl4: careUrl4,

        financeContent1: financeContent1,
        financeTitle1: financeTitle1,
        financeUrl1: financeUrl1,

        financeContent2: financeContent2,
        financeTitle2: financeTitle2,
        financeUrl2: financeUrl2,

        pregnancyContent1: pregnancyContent1,
        pregnancyTitle1: pregnancyTitle1,
        pregnancyUrl1: pregnancyUrl1,

        pregnancyContent2: pregnancyContent2,
        pregnancyTitle2: pregnancyTitle2,
        pregnancyUrl2: pregnancyUrl2,

        parentsContent1: parentsContent1,
        parentsTitle1: parentsTitle1,
        parentsUrl1: parentsUrl1,

        parentsContent2: parentsContent2,
        parentsTitle2: parentsTitle2,
        parentsUrl2: parentsUrl2,

        studyOrgaContent1: studyOrgaContent1,
        studyOrgaTitle1: studyOrgaTitle1,
        studyOrgaUrl1: studyOrgaUrl1,

        studyOrgaContent2: studyOrgaContent2,
        studyOrgaTitle2: studyOrgaTitle2,
        studyOrgaUrl2: studyOrgaUrl2,

        supportContent1: supportContent1,
        supportTitle1: supportTitle1,
        supportUrl1: supportUrl1,

        supportContent2: supportContent2,
        supportTitle2: supportTitle2,
        supportUrl2: supportUrl2,

        connectionContent: connectionContent,
        connectionTitle: connectionTitle,
        connectionUrl: connectionUrl,

    };
    //http://0.0.0.0:4000
    //https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/${user.uid}

    const editContent = async () => {
        let res = axios.put(API_URL + `helpAndFinance/`, ContentData)
        console.log(res);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            await editContent().then(() => navigate("/help-finances"));

        } catch (err) {
            setError(err.message);
        }
    }


    return (
        <div className="container-contents">
            <div className="box-content edit-profile">
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4">{t("edithelpfinances")}</h1>
                        <h2>{t("childcare")}</h2>

                        <Form onSubmit={handleSubmit}>

                            <h3> {contactTitle}</h3>

                            <Form.Group id="contactTitle">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    value={contactTitle}
                                    type="contactTitle"
                                    placeholder={t("title")}
                                    onChange={(e) => setContactTitle(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="contactUrl">
                                <Form.Label>{t("link")}</Form.Label>
                                <Form.Control
                                    value={contactUrl}
                                    type="contactUrl"
                                    placeholder="URL"
                                    onChange={(e) => setContactUrl(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="contactContent">
                                <Form.Label>{t("content")}</Form.Label>
                                <Form.Control
                                    value={contactContent}
                                    type="contactContent"
                                    as="textarea"
                                    placeholder={t("content")}
                                    rows={3}
                                    onChange={(e) => setContactContent(e.target.value)}/>
                            </Form.Group>

                            <h3> {careTitle1} </h3>

                            <Form.Group id="careTitle1">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    value={careTitle1}
                                    type="careTitle1"
                                    placeholder={t("title")}
                                    onChange={(e) => setCareTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="careUrl1">
                                <Form.Label>{t("link")}</Form.Label>
                                <Form.Control
                                    value={careUrl1}
                                    type="careUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setCareUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="careContent1">
                                <Form.Label>{t("content")}</Form.Label>
                                <Form.Control
                                    value={careContent1}
                                    type="careContent1"
                                    as="textarea"
                                    placeholder={t("content")}
                                    rows={3}
                                    onChange={(e) => setCareContent1(e.target.value)}/>
                            </Form.Group>

                            <h3> {careTitle2} </h3>

                            <Form.Group id="careTitle2">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    value={careTitle2}
                                    type="careTitle2"
                                    placeholder={t("title")}
                                    onChange={(e) => setCareTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="careUrl2">
                                <Form.Label>{t("link")}</Form.Label>
                                <Form.Control
                                    value={careUrl2}
                                    type="careUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setCareUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="careContent2">
                                <Form.Label>{t("content")}</Form.Label>
                                <Form.Control
                                    value={careContent2}
                                    type="careContent2"
                                    as="textarea"
                                    placeholder={t("content")}
                                    rows={3}
                                    onChange={(e) => setCareContent2(e.target.value)}/>
                            </Form.Group>

                            <h3> {careTitle3} </h3>

                            <Form.Group id="careTitle3">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    value={careTitle3}
                                    type="careTitle3"
                                    placeholder={t("title")}
                                    onChange={(e) => setCareTitle3(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="careUrl3">
                                <Form.Label>{t("link")}</Form.Label>
                                <Form.Control
                                    value={careUrl3}
                                    type="careUrl3"
                                    placeholder="URL"
                                    onChange={(e) => setCareUrl3(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="careContent3">
                                <Form.Label>{t("content")}</Form.Label>
                                <Form.Control
                                    value={careContent3}
                                    type="careContent3"
                                    as="textarea"
                                    placeholder={t("content")}
                                    rows={3}
                                    onChange={(e) => setCareContent3(e.target.value)}/>
                            </Form.Group>

                            <h3> {careTitle4} </h3>

                            <Form.Group id="careTitle4">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={careTitle4}
                                    type="careTitle4"
                                    placeholder="Titel"
                                    onChange={(e) => setCareTitle4(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="careUrl4">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={careUrl4}
                                    type="careUrl4"
                                    placeholder="URL"
                                    onChange={(e) => setCareUrl4(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="careContent4">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={careContent4}
                                    type="careContent3"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setCareContent4(e.target.value)}/>
                            </Form.Group>
                            <h2>{t("finances")}</h2>

                            <h3> {financeTitle1} </h3>
                            <Form.Group id="financeTitle1">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={financeTitle1}
                                    type="financeTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setFinanceTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="financeUrl1">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={financeUrl1}
                                    type="financeUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setFinanceUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="financeContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={financeContent1}
                                    type="financeContent1"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setFinanceContent1(e.target.value)}/>
                            </Form.Group>

                            <h3> {financeTitle2} </h3>

                            <Form.Group id="financeTitle2">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={financeTitle2}
                                    type="financeTitle2"
                                    placeholder="Titel"
                                    onChange={(e) => setFinanceTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="financeUrl2">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={financeUrl2}
                                    type="financeUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setFinanceUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="financeContent2">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={financeContent2}
                                    type="financeContent2"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setFinanceContent2(e.target.value)}/>
                            </Form.Group>

                            <h2>{t("pregnancy")}</h2>
                            <h3> {pregnancyTitle1} </h3>
                            <Form.Group id="pregnancyTitle1">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={pregnancyTitle1}
                                    type="pregnancyTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setPregnancyTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="pregnancyUrl1">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={pregnancyUrl1}
                                    type="pregnancyUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setPregnancyUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="pregnancyContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={pregnancyContent1}
                                    type="pregnancyContent1"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setPregnancyContent1(e.target.value)}/>
                            </Form.Group>

                            <h3> {pregnancyTitle2} </h3>

                            <Form.Group id="pregnancyTitle2">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={pregnancyTitle2}
                                    type="pregnancyTitle2"
                                    placeholder="Titel"
                                    onChange={(e) => setPregnancyTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="pregnancyUrl2">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={pregnancyUrl2}
                                    type="pregnancyUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setPregnancyUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="pregnancyContent2">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={pregnancyContent2}
                                    type="pregnancyContent2"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setPregnancyContent2(e.target.value)}/>
                            </Form.Group>
                            <h2>{t("studyorga")}</h2>
                            <h3> {studyOrgaTitle1} </h3>


                            <Form.Group id="studyOrgaTitle1">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={studyOrgaTitle1}
                                    type="studyOrgaTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setStudyOrgaTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="studyOrgaUrl1">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={studyOrgaUrl1}
                                    type="studyOrgaUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setStudyOrgaUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="setStudyOrgaContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={studyOrgaContent1}
                                    type="setStudyOrgaContent1"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setStudyOrgaContent1(e.target.value)}/>
                            </Form.Group>

                            <h3> {studyOrgaTitle2} </h3>

                            <Form.Group id="studyOrgaTitle2">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={studyOrgaTitle2}
                                    type="studyOrgaTitle2"
                                    placeholder="Titel"
                                    onChange={(e) => setStudyOrgaTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="studyOrgaUrl2">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={studyOrgaUrl2}
                                    type="studyOrgaUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setStudyOrgaUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="setStudyOrgaContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={studyOrgaContent2}
                                    type="setStudyOrgaContent2"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setStudyOrgaContent2(e.target.value)}/>
                            </Form.Group>


                            <h2>{t("parents")}</h2>
                            <h3> {parentsTitle1} </h3>

                            <Form.Group id="parentsTitle1">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={parentsTitle1}
                                    type="parentsTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setParentsTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="parentsUrl1">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={parentsUrl1}
                                    type="parentsUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setParentsUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="parentsContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={parentsContent1}
                                    type="parentsContent1"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setParentsContent1(e.target.value)}/>
                            </Form.Group>

                            <h3> {parentsTitle2} </h3>

                            <Form.Group id="parentsTitle2">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={parentsTitle2}
                                    type="parentsTitle2"
                                    placeholder="Titel"
                                    onChange={(e) => setParentsTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="parentsUrl2">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={parentsUrl2}
                                    type="parentsUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setParentsUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="parentsContent2">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={parentsContent2}
                                    type="parentsContent2"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setParentsContent2(e.target.value)}/>
                            </Form.Group>

                            <h2>{t("caretaking")}</h2>
                            <h3> {supportTitle1} </h3>

                            <Form.Group id="supportTitle1">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={supportTitle1}
                                    type="supportTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setSupportTitle1(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="supportUrl1">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={supportUrl1}
                                    type="supportUrl1"
                                    placeholder="URL"
                                    onChange={(e) => setSupportUrl1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="supportContent1">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={supportContent1}
                                    type="supportContent1"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setSupportContent1(e.target.value)}/>
                            </Form.Group>
                            <h3> {supportTitle2} </h3>

                            <Form.Group id="supportTitle2">
                                <Form.Label>Titel </Form.Label>
                                <Form.Control
                                    value={supportTitle2}
                                    type="supportTitle1"
                                    placeholder="Titel"
                                    onChange={(e) => setSupportTitle2(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="supportUrl2">
                                <Form.Label>Link </Form.Label>
                                <Form.Control
                                    value={supportUrl2}
                                    type="supportUrl2"
                                    placeholder="URL"
                                    onChange={(e) => setSupportUrl2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="supportContent2">
                                <Form.Label>Inhalt</Form.Label>
                                <Form.Control
                                    value={supportContent2}
                                    type="supportContent2"
                                    as="textarea"
                                    placeholder="Inhalt"
                                    rows={3}
                                    onChange={(e) => setSupportContent2(e.target.value)}/>
                            </Form.Group>

                            <h3> {connectionTitle}</h3>

                            <Form.Group id="connectionTitle">
                                <Form.Label>{t("title")}</Form.Label>
                                <Form.Control
                                    value={connectionTitle}
                                    type="connectionTitle"
                                    placeholder={t("title")}
                                    onChange={(e) => setConnectionTitle(e.target.value)}/>
                            </Form.Group>
                            <Form.Group id="connectionUrl">
                                <Form.Label>{t("link")}</Form.Label>
                                <Form.Control
                                    value={connectionUrl}
                                    type="connectionUrl"
                                    placeholder="URL"
                                    onChange={(e) => setConnectionUrl(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="connectionContent">
                                <Form.Label>{t("content")}</Form.Label>
                                <Form.Control
                                    value={connectionContent}
                                    type="connectionContent"
                                    as="textarea"
                                    placeholder={t("content")}
                                    rows={3}
                                    onChange={(e) => setConnectionContent(e.target.value)}/>
                            </Form.Group>


                            <div className="box-buttons">
                                <button className="button go-to" type="submit">{t("save")}</button>

                            </div>
                        </Form>

                        <Link className="button go-to cancel" to="/help-finances">{t("cancel")}</Link>
                    </Card.Body>
                </Card>
            </div>

        </div>

    )
}

export default EditHelpAndFinance