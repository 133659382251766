import {CHRISTINA_EMAIL, CHRISTINA_PHONE, CHRISTINA_STREET, CHRISTINA_ZIP_CITY} from "../config/Constants";

export const DSGVO = () => {
    return (
        <div className="termsconds">
            <h1>Datenschutzerklärung</h1>

            <h2>1. Einleitung</h2>

            <p>Diese Datenschutzerklärung gilt für die Nutzung der Webseite [www.united-parents.de]. Wir nehmen den
                Schutz Ihrer persönlichen Daten sehr ernst. In dieser Datenschutzerklärung informieren wir Sie darüber,
                wie wir Ihre Daten erheben</p>

            <h2> 2. Verantwortliche Stelle</h2>

            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der EU-Datenschutz-Grundverordnung
                (DSGVO), ist:<br/>

                Christina Jones<br/>
                {CHRISTINA_STREET}<br/>
                {CHRISTINA_ZIP_CITY}<br/>
                <a href={`mailto:${CHRISTINA_EMAIL}`}>{CHRISTINA_EMAIL}</a><br/>
                {CHRISTINA_PHONE}</p>

            <h2>3. Arten der verarbeiteten Daten</h2>

            <h2>3.1. Registrierungsdaten</h2>

            <p>Wenn Sie sich auf unserer Webseite registrieren, erheben wir Informationen wie Ihren gewählten
                Benutzernamen, Ihre studentische E-Mail-Adresse und andere erforderliche Anmeldeinformationen. Diese
                Daten werden für die Authentifizierung und Verwaltung Ihres Kontos verwendet.</p>

            <h2>3.2. Chat-Daten</h2>

            <p>Die Chats, die über unsere Webseite geführt werden, können Textnachrichten enthalten. Diese Informationen
                werden gespeichert und verarbeitet, um die Chat-Funktion bereitzustellen und zu verbessern.</p>

            <h2> 3.3. Zugriffsdaten</h2>

            <p>Wir erheben Daten über jeden Zugriff auf die Webseite (sogenannte Serverlogfiles). Zu den Zugriffsdaten
                gehören:
                <ul>
                    <p>
                        <li>Name der abgerufenen Webseite</li>
                    </p>
                    <p>
                        <li>Datum und Uhrzeit des Abrufs</li>
                    </p>
                    <p>
                        <li>übertragene Datenmenge</li>
                    </p>
                    <p>
                        <li>Meldung über erfolgreichen Abruf</li>
                    </p>
                    <p>
                        <li>Browsertyp und Browserversion</li>
                    </p>
                    <p>
                        <li>Betriebssystem des Nutzers</li>
                    </p>
                    <p>
                        <li>Referrer URL (die zuvor besuchte Seite)</li>
                    </p>
                    <p>
                        <li>IP-Adresse und der anfragende Provider</li>
                    </p>
                </ul>
                Diese Daten dienen ausschließlich der Sicherstellung eines störungsfreien Betriebs der Webseite und zur
                Verbesserung unseres Angebots. Die Daten werden nicht dazu verwendet, Sie persönlich zu identifizieren.
            </p>

            <h2>4. Datenverarbeitung durch Firebase</h2>

            <p>Unsere Webseite verwendet Firebase, einen Dienst von Google, für die Registrierung und Authentifizierung
                von
                Nutzern. Firebase speichert und verarbeitet Ihre Registrierungsdaten gemäß seinen
                Datenschutzbestimmungen.</p>

            <h2>5. Datenverarbeitung und Hosting in Belgien</h2>

            <p>Die Datenbank, in der Ihre Chat-Daten und andere Informationen gespeichert sind, befindet sich auf
                MongoDB-Services in einem Google-Datenzentrum in Belgien, genauer gesagt in St. Ghislain. Google
                speichert
                und verwaltet diese Daten gemäß seinen Datenschutzbestimmungen.</p>

            <h2>6. Ihre Rechte</h2>

            <p>Sie haben das Recht auf Auskunft über die Verarbeitung Ihrer personenbezogenen Daten, das Recht auf
                Berichtigung oder Löschung dieser Daten, das Recht auf Einschränkung der Verarbeitung sowie das Recht
                auf
                Datenübertragbarkeit. Darüber hinaus haben Sie das Recht, der Verarbeitung Ihrer Daten zu widersprechen.
                Bitte wenden Sie sich dazu an <a href="mailto:christinajones@outlook.de">christinajones@outlook.de</a>.
            </p>

            <h2>7. Änderungen dieser Datenschutzerklärung</h2>

            <p>Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf anzupassen, um sie an geänderte rechtliche
                Anforderungen anzupassen oder um Änderungen unserer Dienstleistungen umzusetzen. Die jeweils aktuelle
                Datenschutzerklärung finden Sie auf der Webseite.</p>

            <h2>8. Automatische Löschung von Daten</h2>

            <p>Sobald ein registrierter Nutzer sein Konto selbstständig löscht, erfolgt die sofortige und automatische
                Löschung aller mit diesem Konto verknüpften Daten, einschließlich der Chat-Daten und
                Registrierungsinformationen.
            </p>

            <h2>9. Kontakt</h2>

            <p>Für alle Fragen zur Verarbeitung Ihrer personenbezogenen Daten und zur Ausübung Ihrer Rechte gemäß DSGVO
                wenden Sie sich bitte an:<br/>

                Postanschrift:<br/>
                Christina Jones<br/>
                {CHRISTINA_STREET}<br/>
                {CHRISTINA_ZIP_CITY}<br/>
                <br/>
                Telefonisch unter: {CHRISTINA_PHONE}<br/>
                E-Mail: <a href={`mailto:${CHRISTINA_EMAIL}`}>{CHRISTINA_EMAIL}</a>
            </p>
        </div>
    )
}

export const DSGVO_old = () => {
    return (
        <div className="termsconds">
            <h1>Datenschutzerklärung</h1>
            <h2>Allgemeiner Hinweis und Pflichtinformationen</h2>
            <h2>Benennung der verantwortlichen Stelle</h2>
            <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
            <p>Christina Jones <br/>
                Saalburgallee 19<br/>
                60385 Postfach 600187<br/>
            </p>
            <p>Die verantwortliche Stelle entscheidet allein über die Zwecke und Mittel der Verarbeitung von
                personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>
            <h2>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h2>
            <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein
                Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
                formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
                bleibt vom Widerruf unberührt.</p>
            <h2>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</h2>
            <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
                zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist
                der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens
                befindet.<br/>
                <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">Dieser
                    Link</a> stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit.</p>
            <h2>Recht auf Datenübertragbarkeit</h2>
            <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung
                erfolgt in
                einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen
                Verantwortlichen
                verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
            <h2>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</h2>
            <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche
                Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den
                Zweck der
                Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
                Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit
                über die im
                Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>
            <h2>SSL- bzw. TLS-Verschlüsselung</h2>
            <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als
                Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die
                Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte
                Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>
            <h2>Server-Log-Dateien</h2>
            <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr
                Browser automatisch an uns übermittelt. Dies sind:</p>
            <ul>
                <p>
                    <li>Besuchte Seite auf unserer Domain</li>
                </p>
                <p>
                    <li>Datum und Uhrzeit der Serveranfrage</li>
                </p>
                <p>
                    <li>Browsertyp und Browserversion</li>
                </p>
                <p>
                    <li>Verwendetes Betriebssystem</li>
                </p>
                <p>
                    <li>Referrer URL</li>
                </p>
                <p>
                    <li>Hostname des zugreifenden Rechners</li>
                </p>
                <p>
                    <li>IP-Adresse</li>
                </p>
            </ul>
            <p>
                Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der
                Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
                Vertrags oder vorvertraglicher Maßnahmen gestattet.
            </p>
            <h2>Registrierung auf dieser Website</h2>
            <p>Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Die übermittelten
                Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der
                Registrierung abgefragte Pflichtangaben sind vollständig anzugeben. Andernfalls werden wir die
                Registrierung ablehnen.</p><p>
            Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir Sie per E-Mail. Die E-Mail
            wird an die Adresse versendet, die bei der Registrierung angegeben wurde.
        </p><p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung
            (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich.
            Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
        </p><p>Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Sie auf unserer
            Website registriert sind. Ihren Daten werden gelöscht, sollten Sie Ihre Registrierung aufheben.
            Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>
            <h2>Cookies</h2>
            <p>Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät
                speichert. Cookies helfen uns dabei, unser Angebot nutzerfreundlicher, effektiver und sicherer zu
                machen.
            </p><p>Einige Cookies sind “Session-Cookies.” Solche Cookies werden nach Ende Ihrer Browser-Sitzung von
            selbst gelöscht. Hingegen bleiben andere Cookies auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen.
            Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website wiederzuerkennen.
        </p><p>Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder
            unterbinden. Viele Webbrowser lassen sich so konfigurieren, dass Cookies mit dem Schließen des Programms von
            selbst
            gelöscht werden. Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website
            zur Folge haben.
        </p><p>Das Setzen von Cookies, die zur Ausübung elektronischer Kommunikationsvorgänge oder der Bereitstellung
            bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorb) notwendig sind, erfolgt auf Grundlage von
            Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der
            Speicherung von Cookies zur technisch fehlerfreien und reibungslosen Bereitstellung unserer Dienste.
            Sofern die Setzung anderer Cookies (z.B. für Analyse-Funktionen) erfolgt, werden diese in dieser
            Datenschutzerklärung separat behandelt.</p>
            <h2>Google Analytics</h2>
            <p>Unsere Website verwendet Funktionen des Webanalysedienstes Google Analytics. Anbieter des
                Webanalysedienstes ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.
            </p><p>Google Analytics verwendet "Cookies." Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem
            Endgerät speichert und eine Analyse der Website-Benutzung ermöglichen. Mittels Cookie erzeugte Informationen
            über
            Ihre Benutzung unserer Website werden an einen Server von Google übermittelt und dort gespeichert.
            Server-Standort ist im Regelfall die USA.
        </p>
            <p>Das Setzen von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als
                Betreiber dieser Website haben wir ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
                unser Webangebot und ggf. auch Werbung zu optimieren.</p>
            <h3>IP-Anonymisierung</h3>
            <p>Wir setzen Google Analytics in Verbindung mit der Funktion IP-Anonymisierung ein. Sie gewährleistet,
                dass Google Ihre IP-Adresse innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA
                kürzt. Es kann
                Ausnahmefälle geben, in denen Google die volle IP-Adresse an einen Server in den USA überträgt und dort
                kürzt. In unserem Auftrag wird Google diese Informationen benutzen, um Ihre Nutzung der Website
                auszuwerten, um Reports über Websiteaktivitäten zu erstellen und um weitere mit der Websitenutzung und
                der
                Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen. Es findet keine Zusammenführung
                der von Google Analytics übermittelten IP-Adresse mit anderen Daten von Google statt.
            </p>
            <h3>Browser Plugin</h3>
            <p>Das Setzen von Cookies durch Ihren Webbrowser ist verhinderbar. Einige Funktionen unserer Website
                könnten dadurch jedoch eingeschränkt werden. Ebenso können Sie die Erfassung von Daten bezüglich Ihrer
                Website-Nutzung einschließlich Ihrer IP-Adresse mitsamt anschließender Verarbeitung durch Google
                unterbinden. Dies ist möglich, indem Sie das über <a
                    href="https://tools.google.com/dlpage/gaoptout?hl=de">diesem Link</a> erreichbare Browser-Plugin
                herunterladen und installieren.</p>
            <h3>Widerspruch gegen die Datenerfassung</h3>
            <p>Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link
                klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen
                unserer Website verhindert: Google Analytics deaktivieren.
            </p><p>Einzelheiten zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der <a
            href="https://support.google.com/analytics/answer/6004245?hl=de">Datenschutzerklärung
            von Google</a>.
        </p>
            <h3>Auftragsverarbeitung</h3>
            <p>Zur vollständigen Erfüllung der gesetzlichen Datenschutzvorgaben haben wir mit Google einen Vertrag
                über die Auftragsverarbeitung abgeschlossen.</p>
            <h3>Demografische Merkmale bei Google Analytics</h3>
            <p>Unsere Website verwendet die Funktion “demografische Merkmale” von Google Analytics. Mit ihr lassen
                sich Berichte erstellen, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten.
                Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von
                Drittanbietern.
                Eine Zuordnung der Daten zu einer bestimmten Person ist nicht möglich. Sie können diese Funktion
                jederzeit deaktivieren. Dies ist über die Anzeigeneinstellungen in Ihrem Google-Konto möglich oder indem
                Sie
                die Erfassung Ihrer Daten durch Google Analytics, wie im Punkt “Widerspruch gegen die Datenerfassung”
                erläutert, generell untersagen.
            </p>
        </div>
    )
}

export const Terms = () => {
    return (
        <div className="termsconds">
            <h1>Nutzungsbedingungen</h1>
            <ol>
                <li>Annahme des Vertrags über die Nutzungsbedingungen:
                    <p>Durch das Erstellen eines UNIted-Parents-Kontos, sei es mit einem Mobilgerät, einer mobilen
                        Anwendung oder einem Computer (zusammenfassend der "Dienst" genannt) erklären Sie sich
                        einverstanden, an diese Nutzungsbedingungen und unsere Datenschutzrichtlinie gebunden zu sein.
                        Wenn Sie nicht alle Bedingungen dieses Vertrags annehmen und sich mit diesen nicht einverstanden
                        erklären, bitten wir Sie, den Dienst nicht zu nutzen.
                    </p><p>
                        Wir sind berechtigt, von Zeit zu Zeit Änderungen an diesem Vertrag und an dem Dienst
                        vorzunehmen. Diese können wir aus verschiedenen Gründen vornehmen, z. B. um Gesetzesänderungen
                        oder veränderte gesetzliche Anforderungen, neue Funktionen oder Änderungen von
                        Geschäftspraktiken widerzuspiegeln. Wenn die Änderungen bedeutende Änderungen umfassen, die
                        Ihre Rechte oder Pflichten betreffen, werden wir Sie im Voraus auf angemessene Weise über diese
                        Änderungen informieren (es sei denn, wir sind dazu nach geltendem Recht nicht in der Lage), z.B.
                        per Mitteilungen über den Dienst oder per E-Mail. Wenn Sie den Dienst weiterhin nutzen,
                        nachdem die Änderungen wirksam werden, stimmen Sie dem überarbeiteten Vertrag zu.</p>
                </li>
                <li>Berechtigung:
                    <p>Sie müssen ein/e eingeschriebene/r Student/in sein um die Dienste als „User“ nutzen zu können.
                        Durch das Erstellen eines Kontos und durch die Nutzung des Dienstes erklären und gewährleisten
                        Sie, dass:</p>
                    <ul>
                        <p>
                            <li>Sie einen verbindlichen Vertrag mit UNIted-Parents schließen</li>
                        </p>
                        <p>
                            <li>
                                Sie diesen Vertrag und alle geltenden lokalen, staatlichen, nationalen und
                                internationalen Gesetze, Regeln und Vorschriften einhalten werden
                            </li>
                        </p>
                        <p>
                            <li>
                                Sie zu keinem Zeitpunkt ein Schwerverbrechen oder eine Straftat (oder ein Verbrechen von
                                ähnlichem Ausmaß), ein Sexualverbrechen oder eine Straftat mit Gewalt begangen haben,
                                und dass Sie in keinem staatlichen, bundesstaatlichen oder lokalen
                                Sexualstraftäterregister als Sexualstraftäter geführt werden.
                            </li>
                        </p>
                    </ul>
                </li>
                <li>Ihr Konto:
                    <p>Um UNIted-Parents zu verwenden, müssen sie sich registrieren. Sie sind dafür verantwortlich, die
                        Zugangsdaten, die Sie zur Anmeldung bei UNIted-Parents verwenden, vertraulich zu behandeln, und
                        tragen die
                        alleinige Verantwortung für sämtliche Aktivitäten, die unter diesen Zugangsdaten auftreten. Wenn
                        Sie denken,
                        dass sich jemand Zugriff auf Ihr Konto verschafft hat, kontaktieren Sie uns bitte unverzüglich
                        per E-Mail.</p>

                </li>
                <li>Änderung des Dienstes und Kündigung:
                    <p>UNIted-Parents ist stets bestrebt, den Dienst zu verbessern und Ihnen zusätzliche
                        Funktionalitäten zur
                        Verfügung zu stellen, die Sie als ansprechend und nützlich empfinden. Dies bedeutet, dass wir
                        von Zeit zu
                        Zeit neue Produktfunktionen oder -erweiterungen einbringen sowie einige Funktionen entfernen
                        werden, und
                        wenn diese Maßnahmen keine wesentliche Auswirkung auf Ihre Rechte oder Pflichten haben, dürfen
                        wir diese
                        durchführen, ohne Sie vorher darüber zu informieren. Wir können sogar den Dienst ganz aussetzen.
                    </p><p>
                        Sie können Ihr Konto jederzeit aus jeglichem Grund löschen. UNIted Parents kann Ihr Konto
                        jederzeit ohne
                        Ankündigung löschen, wenn wir der Ansicht sind, dass Sie gegen diesen Vertrag verstoßen haben.
                    </p><p>
                        Wenn Ihr Konto gelöscht wurde, wird dieser Vertrag beendet.</p>
                </li>
                <li>Sicherheit; Ihre Interaktionen mit anderen Mitgliedern:
                    <p>UNIted Parents ist nicht für das Verhalten von Mitgliedern innerhalb oder außerhalb des Dienstes
                        verantwortlich. Sie erklären sich damit einverstanden, bei allen Interaktionen mit anderen
                        Mitgliedern
                        vorsichtig vorzugehen, insbesondere, wenn Sie sich dazu entschließen, außerhalb des Dienstes zu
                        kommunizieren oder die Mitglieder persönlich zu treffen.
                    </p><p>
                        SIE SIND ALLEIN FÜR IHRE INTERAKTIONEN MIT ANDEREN MITGLIEDERN VERANTWORTLICH. SIE SIND SICH
                        DARÜBER
                        BEWUSST, DASS UNITED PARENTS KEINE ÜBERPRÜFUNG DER KRIMINELLEN VERGANGENHEIT BEI SEINEN
                        MITGLIEDERN VORNIMMT
                        ODER SICH ANDERWEITIG ÜBER DIE VERGANGENHEIT UND HINTERGRÜNDE SEINER MITGLIEDER ERKUNDIGT.UNITED
                        PARENTS GIBT KEINE ZUSICHERUNGEN ODER GARANTIEN HINSICHTLICH DES VERHALTENS DER MITGLIEDER.
                    </p>
                </li>
                <li>Rechte, die Sie UNIted Parents gewähren:
                    <p>Sie stimmen zu, dass alle Informationen, die Sie nach dem Erstellen Ihres Kontos übermitteln,
                        zutreffend und wahrheitsgemäß sind.
                    </p><p>
                        Wir können jegliche Inhalte, die nach unserem alleinigen Ermessen gegen diesen Vertrag verstoßen
                        oder den Ruf des Dienstes schädigen könnten, ganz oder teilweise löschen und gegeben falls der
                        Polizei
                        übermitteln.
                    </p><p>
                        Sie stimmen zu, bei der Kommunikation mit unseren Mitarbeitern respektvoll und freundlich zu
                        sein. Wenn wir der Auffassung sind, dass Ihr Verhalten gegenüber unseren Mitarbeitern oder
                        anderen Mitarbeitern zu irgendeinem Zeitpunkt drohend oder beleidigend ist, behalten wir uns das
                        Recht vor, Ihr Konto mit sofortiger Wirkung zu kündigen.
                    </p><p> Wir weisen Sie darauf hin, dass UNIted Parents auf Ihre Kontoinformationen und Inhalte
                        zugreifen, diese speichern und offenlegen kann, wenn dies gesetzlich vorgeschrieben ist, indem
                        es seinen Vertrag mit Ihnen ausführt, oder nach Treu und Glauben, dass dieser Zugriff, diese
                        Speicherung oder Offenlegung einem berechtigten Interesse entspricht, um etwa: (i) einem
                        rechtlichen Verfahren zu entsprechen; (ii) den Vertrag durchzusetzen; (iii) auf Behauptungen zu
                        reagieren, dass irgendwelche Inhalte gegen die Rechte Dritter verstoßen; (iv) auf Ihre Anfragen
                        beim Kundendienst zu reagieren; oder (v) die Rechte, das Eigentum oder die persönliche
                        Sicherheit des Unternehmens oder einer anderen Person zu schützen.
                    </p>
                </li>
                <li>Community-Regeln:
                    <p>Durch die Nutzung des Dienstes erklären Sie sich damit einverstanden, Folgendes zu unterlassen:
                    </p>
                    <ul>
                        <p>
                            <li>
                                den Dienst zu Zwecken zu nutzen, die illegal oder durch diesen Vertrag verboten sind,
                            </li>
                        </p>
                        <p>
                            <li>
                                den Dienst für schädliche oder schändliche Zwecke zu nutzen,
                            </li>
                        </p>
                        <p>
                            <li>
                                den Dienst zu nutzen, UNIted Parents zu schaden,
                            </li>
                        </p>
                        <p>
                            <li>
                                gegen unsere Community-Richtlinien zu verstoßen, die von Zeit zu Zeit aktualisiert
                                werden,
                            </li>
                        </p>
                        <p>
                            <li>
                                Spam zu versenden, Geld von anderen Mitgliedern zu erbitten oder diese zu betrügen,
                            </li>
                        </p>
                        <p>
                            <li>
                                sich als eine andere natürliche oder juristische Person auszugeben oder Bilder von einer
                                anderen Person ohne deren Erlaubnis zu posten,
                            </li>
                        </p>
                        <p>
                            <li>
                                andere zu mobben, zu "stalken", einzuschüchtern, anzugreifen, zu belästigen, schlecht zu
                                behandeln oder zu diffamieren.
                            </li>
                        </p>
                        <p>
                            <li>
                                Inhalte zu posten, welche die Rechte einer Person verletzen oder gegen diese verstoßen,
                                einschließlich der Öffentlichkeits-, Datenschutz-, Urheber-, Markenrechte oder anderer
                                Rechte des geistigen Eigentums oder des Vertragsrechts.
                            </li>
                        </p>
                        <p>
                            <li>
                                Inhalte zu posten, die Hassreden darstellen, bedrohend, sexuell oder pornografisch sind.
                            </li>
                        </p>
                        <p>
                            <li>
                                Inhalte zu posten, die zu Gewalt aufrufen oder Nacktheit oder grundlose Gewalt
                                enthalten.
                            </li>
                        </p>
                        <p>
                            <li>
                                Jegliche Inhalte zu posten, die Rassismus, Fanatismus, Hass oder Körperverletzung
                                jeglicher Art gegen jegliche Gruppe oder Einzelpersonen fördern.
                            </li>
                        </p>
                        <p>
                            <li>
                                Passwörter zu jedweden Zweck oder personenbezogene Daten für kommerzielle oder
                                gesetzwidrige Zwecke von anderen Mitgliedern abzufragen oder die personenbezogenen Daten
                                anderer Personen ohne die Zustimmung der entsprechenden Person zu verbreiten.
                            </li>
                        </p>
                        <p>
                            <li>
                                Das Konto eines anderen Mitglieds zu verwenden, ein Konto mit einem anderen Mitglied zu
                                teilen oder mehr als ein Konto zu unterhalten.
                            </li>
                        </p>
                        <p>
                            <li>
                                Ein anderes Konto zu erstellen, wenn wir bereits Ihr Konto gekündigt haben, es sei denn,
                                Sie haben unsere Erlaubnis.
                            </li>
                        </p>
                    </ul>

                    <p>UNIted Parents behält sich das Recht vor zu kündigen, wenn Sie gegen diese Vereinbarung
                        verstoßen haben, den Dienst missbraucht oder sich in einer Weise verhalten haben, die UNIted
                        Parents als unangemessen oder rechtswidrig betrachtet, darunter Handlungen oder Kommunikation,
                        die innerhalb oder außerhalb des Dienstes stattfinden.
                    </p>
                </li>
                <li>Vorsichtsmaßnahmen:
                    <p>Bei der Nutzung des Dienstes bitten wir Sie vorsichtig bei der Kommunikation mit anderen
                        Mitgliedern zu sein.</p>
                    <ul>
                        <p>
                            <li>keinen genauen Wohnort preisgeben</li>
                        </p>
                        <p>
                            <li>
                                keine Bilder Ihrer Kinder als Profilbild verwenden
                            </li>
                        </p>
                        <p>
                            <li>
                                bei persönlichen Treffen sich vorerst an der jeweiligen Hochschule /Universität
                                verabreden
                            </li>
                        </p>
                    </ul>

                </li>
                <li>Haftungsausschlüsse:
                    <p>UNITED PARENTS STELLT DEN DIENST OHNE MÄNGELGEWÄHR UND IN DER VERFÜGBAREN FORM SOWIE IN DEM
                        UMFANG, DER DURCH GELTENDES RECHT ZULÄSSIG IST, BEREIT UND GEWÄHRT KEINE GARANTIEN JEDWEDER ART,
                        OB AUSDRÜCKLICH, STILLSCHWEIGEND, GESETZLICH ODER ANDERWEITIG IM HINBLICK AUF DEN DIENST
                        (EINSCHLIESSLICH ALLER
                        DARIN ENTHALTENEN INHALTE), EINSCHLIESSLICH, ABER NICHT BESCHRÄNKT AUF JEDWEDE STILLSCHWEIGENDE
                        GARANTIEN FÜR ZUFRIEDENSTELLENDE QUALITÄT, MARKTGÄNGIGKEIT, EIGNUNG FÜR EINEN BESTIMMTEN ZWECK
                        ODER NICHTVERLETZUNG.
                        UNITED PARENTS SICHERT NICHT ZU UND GARANTIERT NICHT, DASS (A) DER DIENST UNUNTERBROCHEN, SICHER
                        ODER FEHLERFREI SEIN WIRD, (B) ETWAIGE MÄNGEL ODER FEHLER IM DIENST BEHOBEN WERDEN, ODER (C)
                        DASS INHALTE ODER INFORMATIONEN, DIE SIE AUF DEM DIENST ODER DURCH DIESEN ERHALTEN, KORREKT
                        SIND.
                    </p><p>
                        UNITED PARENTS ÜBERNIMMT KEINE VERANTWORTUNG FÜR INHALTE, DIE SIE, ANDERE MITGLIEDER ODER DRITTE
                        ÜBER DEN DIENST POSTEN, VERSENDEN ODER ERHALTEN. DER ZUGRIFF AUF JEGLICHES MATERIAL, DAS
                        HERUNTERGELADEN ODER DURCH DIE NUTZUNG DES DIENSTES ANDERWEITIG ERHALTEN WIRD, ERFOLGT NACH
                        EIGENEM ERMESSEN UND AUF EIGENE GEFAHR.
                    </p>
                </li>
                <li>Dienste Dritter:
                    <p>Der Dienst kann Werbung und Werbeaktionen, die von Dritten angeboten werden, sowie Links zu
                        anderen Websites oder Internet-Ressourcen enthalten. UNIted Parents ist nicht verantwortlich für
                        die Verfügbarkeit (oder mangelnde Verfügbarkeit) solcher externen Websites oder
                        Internet-Ressourcen. Wenn Sie beschließen, mit Dritten über unseren Dienst zu interagieren,
                        unterliegt deren Beziehung mit Ihnen den Bedingungen dieser Dritten. UNIted Parents ist nicht
                        verantwortlich oder haftbar für die Bedingungen oder Maßnahmen dieser Dritten.
                    </p>
                </li>
            </ol>

        </div>
    )
}

