import {Tooltip} from "react-bootstrap";
import {aboutMe} from "./aboutMe";

export const tooltips = {
    "tt_aboutme": (<Tooltip id="tooltip"><p className="tooltiptext">{aboutMe.hinttext}</p></Tooltip>),
    "tt_plz": (<Tooltip id="tooltip"><p className="tooltiptext">PLZ: XXXXX</p></Tooltip>),
    "tt_city": (<Tooltip id="tooltip"><p className="tooltiptext">Standort</p></Tooltip>),
    "tt_course": (<Tooltip id="tooltip"><p className="tooltiptext">Studiengang</p></Tooltip>),
    "tt_kidsAge": (<Tooltip id="tooltip"><p className="tooltiptext">Wie alt sind deine Kinder?</p></Tooltip>),
    "tt_kidsNr": (<Tooltip id="tooltip"><p className="tooltiptext">Wie viele Kinder hast du?</p></Tooltip>),
    "tt_toProfile": (<Tooltip id="tooltip"><p className="tooltiptext">Zum Profil</p></Tooltip>),
    "tt_blockUser": (<Tooltip id="tooltip"><p className="tooltiptext">Block User</p></Tooltip>),
    "tt_username": (
        <Tooltip id="tooltip"><p className="tooltiptext">Achtung: Username kann nicht mehr geändert werden!</p>
        </Tooltip>)
}