import React from "react";

/**
 * List Items for More Help Content at Helps & Finances page
 * contains: image src for logos, title and a button for further information
 * @param {*} props
 * @returns List Item component
 */


function MoreHelpListItems(props) {
    return (
        <li>
            <div className="list-content">
                <div className="box-logo">
                    <img className="img logo-img" src={props.imageSrc} alt="logo-img"></img>
                </div>
                <a className="open-URL" href={props.URL} target="_blank"><h2>{props.title}</h2></a>
            </div>
        </li>
    )
}

export default MoreHelpListItems