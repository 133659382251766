// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyAyhrYmXnuzaGa4xTgwEIATdbbfNDPVgPI",
    authDomain: "united-parents-hub.firebaseapp.com",
    projectId: "united-parents-hub",
    storageBucket: "united-parents-hub.appspot.com",
    messagingSenderId: "1004184879997",
    appId: "1:1004184879997:web:6c036f65ab3c45b1d40214"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const storage = getStorage(app)
export default app