import React, {useEffect, useState} from "react";
import axios from "axios";
import {useUserAuth} from "../context/UserAuthContext"
import {OverlayTrigger, Table} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {tooltips} from "../utils/tooltips";
import {API_URL} from "../helpers/HttpService";


/**
 * User Profile content
 * displays user info given at the registration
 * option to edit profile
 * calendar to display times when childcare needed/can be provided
 * @returns user info
 */

// axios.get(`https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/blockedusers/${user.uid}`,
/*const blockUser = async () => {
                  //axios.put(`https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/delete/blockedusers/${user.uid`)
        let res = axios.put(`https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/blockedusers/${user.uid}`, data.state.uid, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        })
        console.log(res);
    }*/

function UserProfile() {

    const [a, setA] = useState("A3");
    const [b, setB] = useState("");
    const [c, setC] = useState("");
    const [d, setD] = useState("");
    const [eValue, setE] = useState("");
    const [f, setF] = useState("");
    const [g, setG] = useState("");
    const [h, setH] = useState("");
    const [i, setI] = useState("");
    const [j, setJ] = useState("");
    const [k, setK] = useState("");
    const [l, setL] = useState("");
    const [m, setM] = useState("");
    const [n, setN] = useState("");
    const [o, setO] = useState("");
    const [p, setP] = useState("");
    const [q, setQ] = useState("");
    const [r, setR] = useState("");
    const [s, setS] = useState("");
    const [tValue, setT] = useState("");
    const [u, setU] = useState("");
    const [userAge, setUserAge] = useState('');
    const [userName, setUserName] = useState('');
    const [uniName, setUniName] = useState('');
    const [kidsNumb, setKidsNumb] = useState('');
    const [photoUrl, setphotoUrl] = useState('')
    const [uid, setUID] = useState('')
    const data = useLocation();
    const [course, setCourse] = useState('');
    const [kidsAge, setKidsAge] = useState('');
    const [about, setAbout] = useState('');
    const [token, setToken] = useState('');
    const {user} = useUserAuth();
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()


    useEffect(() => {


        if (data.state.uid !== undefined) {
            setUID(data.state.uid)

        }
        // if (user.photoURL) {
        setphotoUrl(`https://firebasestorage.googleapis.com/v0/b/united-parents-hub.appspot.com/o/images%2F${uid}?alt=media&token=b7213d01-a694-4828-9b90-e7edb5b92df1`)
        // } else {
        //     /**
        //      * Default profile image when no image has been uploaded
        //      */
        //     setphotoUrl('https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png')
        // }
        // setUid(user.uid);
        setToken(user.accessToken)

        const getUser = async () => {
            try {
                if (uid !== '') {
                    await axios.get(API_URL + `users/${uid}`, {
                        headers: {
                            Authorization: 'Bearer ' + user.accessToken
                        }
                    }).then((res) => {
                        console.log(res)


                        setA(res.data?.result.A)
                        setB(res.data?.result.B)
                        setC(res.data?.result.C)
                        setD(res.data?.result.D)
                        setE(res.data?.result.E)
                        setF(res.data?.result.F)
                        setG(res.data?.result.G)
                        setH(res.data?.result.H)
                        setI(res.data?.result.I)
                        setJ(res.data?.result.J)
                        setK(res.data?.result.K)
                        setL(res.data?.result.L)
                        setM(res.data?.result.M)
                        setN(res.data?.result.N)
                        setO(res.data?.result.O)
                        setP(res.data?.result.P)
                        setQ(res.data?.result.Q)
                        setR(res.data?.result.R)
                        setS(res.data?.result.S)
                        setT(res.data?.result.T)
                        setU(res.data?.result.U)

                        setUserAge(res.data
                            ?.result.age)
                        setUserName(res.data
                            ?.result.username)
                        setKidsNumb(res.data
                            ?.result.kidsNumb)
                        setCourse(res.data
                            ?.result.course)
                        setKidsAge(res.data
                            ?.result.kidsAge)
                        setAbout(res.data
                            ?.result.about)
                        setUniName(res.data
                            ?.result.uniName)
                    })
                }

            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }
        getUser();


    }, [uid])

    const blockUser = async () => {
        //axios.put(`https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/delete/blockedusers/${user.uid`)
        axios.put(API_URL + `users/blockedusers/${user.uid}`,
            {blockedUsers: data.state.uid}, {
                headers: {
                    Authorization: 'Bearer ' + user.accessToken
                }
            }).then((res) => {
            if (res.status === 200 || res.status === 201) {
                alert("User " + userName + " " + t("userblocked"))
                navigate("/home")
            } else {
                alert(t("userblockerror") + "support@united-parents.de")
            }
        })
    }


    function calcUserAge() {
        var today = new Date();
        var birthDate = new Date(userAge);
        var age = (today.getFullYear() - birthDate.getFullYear())
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    const calculatedAge = calcUserAge();

    return (
        <div className="container-contents">
            <div className="box-content profile">

                <div className="profile-img">
                    <img className="circle-img" src={photoUrl} alt="user-img"
                         onError={() => setphotoUrl('https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png')}></img>
                    <Link to={"/chats"} state={{receiver: uid}}>
                        <button className="button go-to send-msg">
                            <span className="material-icons">forum</span>
                        </button>
                    </Link>
                </div>

                <div className="profile-info">
                    <h1>{userName}</h1>
                    <h2 id="userAge"><strong>{t("age")}:</strong> {calculatedAge}</h2>
                    <h2>
                        <strong>{t("university")}:</strong> {user.email?.includes("@stud.fra-uas.de") ? "Frankfurt University of Applied Sciences" : ""}
                    </h2>
                    <h2><strong>{t("course")}:</strong> {course}</h2>
                    <h2><strong>{t("children")}:</strong> {kidsNumb}</h2>
                    <h2><strong>{t("agechildren")}:</strong> {kidsAge}</h2>
                </div>
                <div className="home-hlp box-icon">
                    <OverlayTrigger placement="top" overlay={tooltips.tt_blockUser}>
                        <p className="block-btn">
                            <span className="material-icons" onClick={blockUser}>block</span>
                        </p></OverlayTrigger>
                </div>
            </div>

            <div className="box-content about-me">
                <h1>{t("aboutme")}:</h1>
                <h2>{about}</h2>
            </div>

            <div className="box-content">
                <p>{t("timesforchildcare")}:</p>
                <Table className="calendar" bordered responsive="sm">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{t("morning")}</th>
                        <th>{t("midday")}</th>
                        <th>{t("evening")}</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>{t("monday.abbr")}</td>
                        <td className={a === "A1" ? "yellow" : a === "A2" ? "pink" : a === "A3" ? "grey" : ""}></td>
                        <td className={b === "B1" ? "yellow" : b === "B2" ? "pink" : b === "B3" ? "grey" : ""}></td>
                        <td className={c === "C1" ? "yellow" : c === "C2" ? "pink" : c === "C3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("tuesday.abbr")}</td>
                        <td className={d === "D1" ? "yellow" : d === "D2" ? "pink" : d === "D3" ? "grey" : ""}></td>
                        <td className={eValue === "E1" ? "yellow" : eValue === "E2" ? "pink" : eValue === "E3" ? "grey" : ""}></td>
                        <td className={f === "F1" ? "yellow" : f === "F2" ? "pink" : f === "F3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("wednesday.abbr")}</td>
                        <td className={g === "G1" ? "yellow" : g === "G2" ? "pink" : g === "G3" ? "grey" : ""}></td>
                        <td className={h === "H1" ? "yellow" : h === "H2" ? "pink" : h === "H3" ? "grey" : ""}></td>
                        <td className={i === "I1" ? "yellow" : i === "I2" ? "pink" : i === "I3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("thursday.abbr")}</td>
                        <td className={j === "J1" ? "yellow" : j === "J2" ? "pink" : j === "J3" ? "grey" : ""}></td>
                        <td className={k === "K1" ? "yellow" : k === "K2" ? "pink" : k === "K3" ? "grey" : ""}></td>
                        <td className={l === "L1" ? "yellow" : l === "L2" ? "pink" : l === "L3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("friday.abbr")}</td>
                        <td className={m === "M1" ? "yellow" : m === "M2" ? "pink" : m === "M3" ? "grey" : ""}></td>
                        <td className={n === "N1" ? "yellow" : n === "N2" ? "pink" : n === "N3" ? "grey" : ""}></td>
                        <td className={o === "O1" ? "yellow" : o === "O2" ? "pink" : o === "O3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("saturday.abbr")}</td>
                        <td className={p === "P1" ? "yellow" : p === "P2" ? "pink" : p === "P3" ? "grey" : ""}></td>
                        <td className={q === "Q1" ? "yellow" : q === "Q2" ? "pink" : q === "Q3" ? "grey" : ""}></td>
                        <td className={r === "R1" ? "yellow" : r === "R2" ? "pink" : r === "R3" ? "grey" : ""}></td>
                    </tr>

                    <tr>
                        <td>{t("sunday.abbr")}</td>
                        <td className={s === "S1" ? "yellow" : s === "S2" ? "pink" : s === "S3" ? "grey" : ""}></td>
                        <td className={tValue === "T1" ? "yellow" : tValue === "T2" ? "pink" : tValue === "T3" ? "grey" : ""}></td>
                        <td className={u === "U1" ? "yellow" : u === "U2" ? "pink" : u === "U3" ? "grey" : ""}></td>
                    </tr>
                    </tbody>
                </Table>
                <div className="table-legend">
                    <div className="offer-childcare yellow">{t("available")}</div>
                    <div className="need-childcare pink">{t("inneed")}</div>
                    <div className="nothing grey">{t("neither")}</div>
                </div>
            </div>
        </div>
    );
}

export default UserProfile;