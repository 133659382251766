import React from "react";

export const welcomeMessage = {
    "messageFirst":
        <div className="welcome-p">
            <p>Herzlich willkommen bei <strong>UNIted Parents</strong>!</p>
            <p>Hier auf der Startseite findest du aktuelle Informationen deiner Hochschule/Universität, die
                für Studierende mit Kind interessant sind.
            </p>
            <p>
                Wenn du Unterstützung beim Studieren mit Kind benötigst, gibt es unter "Beratung und Informationen"
                einen umfassenden Überblick über die Beratung und Angebote in der Hochschule/Universität bei der du
                eingeschrieben bist.
            </p>
            <p>
                Unabhängig davon bietet UNIted Parents dir die Möglichkeit, dich mit anderen interessierten Studierenden
                aller angeschlossenen Hochschulen, z. B. am Heimatort zu vernetzen, um euch z. B. bei der
                Kinderbetreuung gegenseitig zu unterstützen oder einfach nur kennenzulernen.
            </p>
            <p>
                So könnt ihr über den Chat erste Kontakte herstellen, aber auch wertvolle Tipps und Erfahrungen im
                Alltag mit Kindern und Studium austauschen.
            </p>
            <p>
                Das Angebot ist aus einem studentischen Projekt entstanden und wurde an der Frankfurt University of
                Applied Sciences von Studierenden entwickelt.
            </p>
            <p>
                Momentan befindet sich UNIted Parents in der Testphase und wird im Sommersemester erstmals an der
                Frankfurt University of Applied Sciences und der Goethe-Universität Frankfurt angeboten. Ihr könnt alle
                Funktionen in der Test-Phase nutzen und wir freuen uns über eure Fragen, Anregungen, etc. unter
                <a href="mailto:support@united-parents.de"> support@united-parents.de</a>.
            </p>
            <p>
                Wir wünschen dir viel Spaß beim Vernetzen!
            </p>
            <p>
                <i>- Dein UNIted Parents Team</i>
            </p>
        </div>,
    "messageShort":
        <div className="welcome-p">
            <p>
                <strong>UNItedParents</strong> - Gemeinsam Familie und Studium meistern!
            </p>
            <p>
                Studium und Kinderbetreuung können große Herausforderungen mit sich bringen. Aber bei
                UNItedParents wirst du nie allein gelassen!
            </p>
            <p>
                Lass uns gemeinsam anpacken und uns über unseren Chat kennenlernen. Wir unterstützen uns
                gegenseitig, teilen hilfreiche Tipps und Ratschläge und wechseln uns bei der Kinderbetreuung
                ab, um gemeinsam die Hürden zu überwinden.
            </p>
            <p>
                Unter dem Bereich Beratung und Information findest du zudem Angebote deiner Hochschule/Universität.
            </p>
            <p>
                UNItedParents ist der Ort, an dem Inspiration und Unterstützung auf dich warten. Gemeinsam
                meistern wir den Balanceakt zwischen Familie und Studium und gehen mit Zuversicht auf dem
                Weg zum Abschluss.
            </p>
            <p>
                <i> - Dein UNIted Parents Team</i>
            </p>
        </div>,
    "messageShortLogin":
        <div className="welcome-p">
            <p>
                <strong>UNItedParents</strong> - Gemeinsam Familie und Studium meistern!
            </p>
            <div className="box-logo up-logo">
                <img src="./images/upLogo-noBG.png" className="img up-logo" alt="up-logo-img"></img>
            </div>
            <p>
                Studium und Kinderbetreuung können große Herausforderungen mit sich bringen. Aber bei
                UNItedParents wirst du nie allein gelassen!
            </p>
            <p>
                Lass uns gemeinsam anpacken und uns über unseren Chat kennenlernen. Wir unterstützen uns
                gegenseitig, teilen hilfreiche Tipps und Ratschläge und wechseln uns bei der Kinderbetreuung
                ab, um gemeinsam die Hürden zu überwinden.
            </p>
            <p>
                Unter dem Bereich Beratung und Information findest du zudem Angebote deiner Hochschule/Universität.
            </p>
            <p>
                UNItedParents ist der Ort, an dem Inspiration und Unterstützung auf dich warten. Gemeinsam
                meistern wir den Balanceakt zwischen Familie und Studium und gehen mit Zuversicht auf dem
                Weg zum Abschluss.
            </p>
            <p>
                Das Angebot ist aus einem studentischen Projekt entstanden und wurde an der Frankfurt University of
                Applied Sciences von Studierenden entwickelt. Die WebApp wird kostenfrei als unabhängiger Dienst
                zur Verfügung gestellt.
            </p>
            <p>
                Momentan befindet sich UNIted Parents in der Testphase und wird im Sommersemester erstmals an der
                Frankfurt University of Applied Sciences und der Goethe-Universität Frankfurt angeboten. Ihr könnt alle
                Funktionen in der Test-Phase nutzen und wir freuen uns über eure Fragen, Anregungen, etc. unter
                <a href="mailto:support@united-parents.de"> support@united-parents.de</a>.
            </p>
            <p>
                <i>- Dein UNIted Parents Team</i>
            </p>
        </div>
}