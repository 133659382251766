import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const TermsConds = ({children}) => {
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()

    return (
        <div className="container-contents terms">
            <button className="text-link" onClick={() => navigate(-1)}>{t("back")}</button>
            <br/>
            {children}
        </div>
    )
}