import i18n from "i18next";
import Backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";


i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "de",   //default language
        fallbackLng: "de",
        debug: true,
        backend: {
            /* translation file path */
            loadPath: "/assets/{{ns}}/{{lng}}.json",
        },


        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */


        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            useSuspense: true
        },
    });

export default i18n;