import {CHRISTINA_EMAIL, CHRISTINA_PHONE, CHRISTINA_STREET, CHRISTINA_ZIP_CITY} from "../config/Constants";

export const Impressum = () => {
    return (
        <div className="termsconds">
            <h1>Impressum</h1>
            <h1>Angaben gemäß § 5 TMG:</h1>
            <p>Studentin<br/>
                Christina Jones</p>
            <h2>Postanschrift:</h2>
            <p>{CHRISTINA_STREET}<br/>
                {CHRISTINA_ZIP_CITY}</p>
            <h2>Kontakt:</h2>
            <p> Telefon: {CHRISTINA_PHONE}<br/>
                E-Mail: <a href={`mailto:${CHRISTINA_EMAIL}`}>{CHRISTINA_EMAIL}</a></p>


        </div>
    )
}