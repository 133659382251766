import React from "react";
import {Button, Card} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Card for the Help & Finances page
 * contains: title, content & URL for further information
 * @param {*} props
 * @returns Card component
 */

function HelpCard(props) {

    const {t, i18n} = useTranslation();


    return (
        props.title.length > 0 ?
            <Card>
                <Card.Body>
                    <Card.Title><h2>{props.title}</h2></Card.Title>
                    <Card.Text>{props.content}</Card.Text>
                    <Button className="link-btn"
                            onClick={() => window.open(props.URL)}
                    >{t("moreinfo")}</Button>
                </Card.Body>
            </Card> : null
    )
}

export default HelpCard