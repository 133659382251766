export const moreHelpData = {
    "data": [
        {
            id: 0,
            imageSrc: '/images/mainSwerk-logo.png',
            title: 'Studierendenwerk',
            URL: 'https://www.studentenwerkfrankfurt.de/'
        },
        {
            id: 1,
            imageSrc: '/images/fgz-logo.png',
            title: 'Familien Gesundheitszentrum',
            URL: 'https://www.fgzn.de/'
        },
        {
            id: 2,
            imageSrc: '/images/fbf-logo.png',
            title: 'Frankfurter Bündnis für Familien',
            URL: 'https://www.frankfurter-buendnis-fuer-familien.de/'
        },
        {
            id: 3,
            imageSrc: '/images/fgf-logo.png',
            title: 'Frauen-Guide Frankfurt',
            URL: 'https://frankfurt.de/service-und-rathaus/verwaltung/publikationen/frauenreferat/frauen-guide'
        },
        {
            id: 4,
            imageSrc: '/images/ifz-logo.png',
            title: 'Internationales Familienzentrum',
            URL: 'https://ifz-ev.de/'
        },
        {
            id: 5,
            imageSrc: '/images/mukiva-logo.png',
            title: 'MuKiVa',
            URL: 'https://mukiva.de/'
        },
        {
            id: 6,
            imageSrc: '/images/profamilia-logo.svg',
            title: 'Profamilia',
            URL: 'https://www.profamilia.de/angebote-vor-ort/hessen/beratungsstelle-frankfurt-main'
        }
    ]
}