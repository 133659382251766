import axios from "axios";
import io from "socket.io-client";
import {appConfig} from "../config/AppConfig";

export const API_URL = appConfig.apiUrl;
export const socket = io.connect(API_URL);

const api = axios.create({
    baseURL: API_URL
});

export const getData = async (endPoint, accessToken) => {
    try {
        const response = await api.get(endPoint, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const postData = async (endPoint, formData, accessToken) => {
    try {
        const response = await api.post(endPoint, formData, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const putData = async (endPoint, formData, accessToken) => {
    try {
        const response = await api.put(endPoint, formData, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        });
        return response.data;
    } catch (error) {
        return error;
    }
};

export const deleteData = async (endPoint, accessToken) => {
    try {
        const response = await api.delete(endPoint, {
            headers: {
                Authorization: 'Bearer ' + accessToken
            }
        })
        console.log("Deleting response: " + response)
        return response.data
    } catch (error) {
        console.error("Error while deleting data: " + error)
        return error
    }
}