import React, {createContext, useContext, useEffect, useState} from "react";
import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    sendEmailVerification,
    signInWithEmailAndPassword
} from "firebase/auth";
import {auth} from "../Firebase";
import {useTranslation} from "react-i18next";

const UserAuthContext = createContext(null);  // Setze null als Initialwert

export function UserAuthContextProvider({children}) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true); // Loading-Flag für Initialzustand
    const {t} = useTranslation()

    async function register(email, password) {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            console.log("Create User result: ", userCredential);
            if (user === auth.currentUser) {
                try {
                    await sendEmailVerification(user);
                    alert(t("verification-email-sent"));
                } catch (exception) {
                    console.error("Error sending verification mail", exception);
                    return {
                        success: false,
                        cause: "Beim Senden der Verifizierungs-E-Mail ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Eingabe."
                    };
                }
            } else {
                console.error(`Created User and logged in User are not identical:\nCreated User: ${user}\nLogged in User: ${auth.currentUser}`);
                return {
                    success: false,
                    cause: "Registrierter Nutzer konnte nicht zugeordnet werden."
                };
            }
            return {success: true};
        } catch (exception) {
            console.error(`User creation failed`, exception);
            return {
                success: false,
                cause: `Beim Erstellen des Nutzers für ${email} ist ein Fehler aufgetreten.`
            };
        }
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);  // Setzt den aktuellen Benutzer
            setLoading(false);     // Ladezustand auf false setzen
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserAuthContext.Provider value={{user, loading, register, login}}>
            {children}
        </UserAuthContext.Provider>
    );
}

export function useUserAuth() {
    const context = useContext(UserAuthContext);
    if (!context) {
        throw new Error("useUserAuth must be used within a UserAuthProvider");
    }
    return context;
}
