import {useUserAuth} from "../context/UserAuthContext";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {RingLoader} from "react-spinners";
import {useTranslation} from "react-i18next";
import {sendEmailVerification} from "firebase/auth";

export const WaitForEmailVerification = () => {
    const {user, loading} = useUserAuth()
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        if (!loading && user) {
            if (user.emailVerified) {
                navigate("/registration2")
            }
        }
    }, [user]);

    useEffect(() => {
        if (!loading && user && !user.emailVerified) {
            const interval = setInterval(() => {
                user.reload().then(() => {
                    if (user.emailVerified) {
                        navigate('/registration2');
                    }
                });
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [user, loading, navigate]);

    const resendEmail = async () => {
        if (user && !user.emailVerified) {
            try {
                await sendEmailVerification(user)
                alert("Bestätigungsmail wurde erneut versendet")
            } catch (e) {
                if (e.code === 'auth/too-many-requests') {
                    alert("Es wurde vor Kurzem eine E-Mail versendet. Bitte überprüfe deinen Spam-Ordner und versuche es gegebenenfalls später erneut.")
                }

            }
        }
    }

    return <div className="container-login-register">
        <div className="box-content">
            <div className="content-column-big-gap">
                <h1>{t("verify-email-header")}</h1>
                <RingLoader/>
                <div className="content-column-small-gap">
                    <p>{t("verify-email-info")}</p>
                    <button onClick={resendEmail}>{t("verify-email-resend")}</button>
                </div>
            </div>
        </div>
    </div>
}